import React from "react"

import { DimSumMenu, Layout, SEO } from "src/components"
import { PATHS } from "src/constants"

const DimSumMenuPage = props => {
  const { location } = props
  const activeNavLink = PATHS[location.pathname]

  return (
    <Layout activeNavLink={activeNavLink}>
      <SEO title="New Golden Dragon | Dim Sum Menu" />
      <DimSumMenu />
    </Layout>
  )
}

export default DimSumMenuPage
